import { createBrowserRouter } from 'react-router-dom';
import { PrivateRoute } from 'app/components/PrivateRoute';
import Authenticated from 'app/components/Authenticated';
import auth from 'app/router/routes/auth';
import calendar from 'app/router/routes/calendar';
import SettingsWrapper from 'app/pages/RestaurantSettings/_components/SettingsWrapper';
import settings from 'app/router/routes/settings/settings';
import mozrest from 'app/router/routes/mozrest';

const router = createBrowserRouter([
  ...auth,
  ...mozrest,
  {
    path: '/',
    element: (
      <PrivateRoute>
        <Authenticated />
      </PrivateRoute>
    ),
    children: [
      ...calendar,
      {
        element: <SettingsWrapper />,
        children: [...settings],
      },
    ],
  },
]);

export default router;
