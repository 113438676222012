import paths from '../../paths';
import { lazy } from 'react';

const PoliciesAndTermsPage = lazy(() => import('app/pages/RestaurantSettings/PoliciesAndTerms'));
const WidgetSettingsPage = lazy(() => import('app/pages/RestaurantSettings/WidgetSettings'));
const WidgetFields = lazy(() => import('app/pages/RestaurantSettings/WidgetFields'));
const WidgetIntegrationPage = lazy(() => import('app/pages/RestaurantSettings/WidgetIntegration'));

const onlineReservations = [
  {
    path: paths.settings.onlineReservations.widgetSettings,
    element: <WidgetSettingsPage />,
  },
  {
    path: paths.settings.onlineReservations.widgetFields,
    element: <WidgetFields />,
  },
  {
    path: paths.settings.onlineReservations.policiesAndTerms,
    element: <PoliciesAndTermsPage />,
  },
  {
    path: paths.settings.onlineReservations.widgetIntegration,
    element: <WidgetIntegrationPage />,
  },
];

export default onlineReservations;
