import { getFileCall } from 'api/app/file';
import { IFile } from 'types/app/file';

export const useFile = () => {
  return {
    getBlobURL: async (file: IFile) => {
      const response = await getFileCall(file.url);
      const fileBlob = new Blob([response], { type: file.mime });
      return URL.createObjectURL(fileBlob);
    },
    getAbsoluteURL: (file: IFile) => {
      return `${process.env.REACT_APP_API_BASE_URL}storage/${file.url}`;
    },
  };
};
