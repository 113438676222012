import { lazy } from 'react';
import paths from '../../paths';

const ReservationSettingsPage = lazy(
  () => import('app/pages/RestaurantSettings/ReservationSettings'),
);

const calendarAndList = [
  {
    path: paths.settings.calendarAndList.settings,
    element: <ReservationSettingsPage />,
  },
];

export default calendarAndList;
