import { useLocation } from 'react-router-dom';

const useRouteMatcher = () => {
  const location = useLocation();

  const routeIs = (path: string) => {
    return location.pathname === path;
  };

  return { routeIs };
};

export default useRouteMatcher;
