import { StyledCard } from 'app/components/StyledComponents/StyledCard';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { SubNavLink } from 'app/pages/RestaurantSettings/_components/SubNavLink';
import { ReactComponent as ChevronRight } from 'images/icons/ic-chevron-right.svg';
import './SettingsSubNavigation.scss';
import { useScreenSize } from 'hooks/useScreenSize';
import { settingsSubNavigationLinks } from 'app/pages/RestaurantSettings/_components/SettingsSubNavigation/_config';
import ScrollArea from 'app/components/ScrollArea';
import { useTheme } from '@mui/material/styles';
import { SettingHeader } from 'app/components/SettingHeader';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import paths from 'app/router/paths';

interface OwnProps {
  visible: boolean;
  setNavigationValues: any;
}

export const SettingsSubNavigation = ({ setNavigationValues, visible }: OwnProps) => {
  const { t } = useTranslation();
  const { isMax760 } = useScreenSize();
  const showMobileSubNavigation = isMax760 ? !visible : false;
  const subNavigationClass = showMobileSubNavigation ? 'mobile' : 'desktop';
  const theme = useTheme();
  const scrollRef = useRef(null);
  const navigate = useNavigate();

  const settingsSubNavigationLinksToRender = settingsSubNavigationLinks(t).map(
    ({ title, links }, index) => (
      <Box key={`${title}-${index}`}>
        <StyledCard sx={{ padding: '16px' }}>
          <Typography
            sx={{
              fontWeight: 600,
              marginBottom: '8px',
              textTransform: 'uppercase',
              color: 'navyBlue',
              padding: '0 8px',
            }}
          >
            {title}
          </Typography>
          {/* @ts-ignore */}
          {links.map(({ to, name, icon, target, disabled }) => (
            <SubNavLink
              to={to}
              target={target}
              key={name}
              disabled={disabled}
              onClick={(e) => {
                disabled && e.preventDefault();
                isMax760 && setNavigationValues({ visible: true });
              }}
            >
              {icon}
              <Box className="linkContent">
                <Box className="linkTextContainer">
                  <Typography className="linkText sub-nav-link">{name}</Typography>
                </Box>
                <ChevronRight />
              </Box>
            </SubNavLink>
          ))}
        </StyledCard>
      </Box>
    ),
  );

  return (
    <Box
      className={`subNavigationContainer ${subNavigationClass}`}
      sx={{
        height: '100%',
      }}
    >
      <ScrollArea style={{ borderRadius: theme.borderRadius.small }} scrollRef={scrollRef}>
        <SettingHeader
          scrollRef={scrollRef}
          title={t('settingsTitle')}
          withoutSaveButton={true}
          onBack={() => navigate(paths.calendar.reservationList)}
          isBackArrow={true}
          sx={{
            width: '100%',
            display: { xs: 'block', md: 'none' },
          }}
        />
        <Box className={`settingsSubNavigation ${subNavigationClass}`}>
          {settingsSubNavigationLinksToRender}
        </Box>
      </ScrollArea>
    </Box>
  );
};
