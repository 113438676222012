import paths from '../paths';
import { lazy } from 'react';

const LoginPage = lazy(() => import('app/pages/Login'));
const ForgotPasswordPage = lazy(() => import('app/pages/ForgotPassword'));

const auth = [
  {
    path: paths.auth.login,
    element: <LoginPage />,
  },
  {
    path: paths.auth.forgotPassword,
    element: <ForgotPasswordPage />,
  },
];

export default auth;
