import { ReactNode } from 'react';
import { Box, Button, CircularProgress, ButtonProps, Typography } from '@mui/material';
import { useAppSelector } from 'redux/hooks';

interface OwnProps extends ButtonProps {
  children: ReactNode;
  isLoading?: boolean;
  isDisabled?: boolean;
  withMargin?: boolean;
  form?: string;
}

export const SubmitButton = ({
  children,
  isDisabled,
  withMargin = true,
  form,
  ...props
}: OwnProps) => {
  const { is_submit_available, is_submit_pending } = useAppSelector((state) => state.app.app);

  return (
    <Button
      color="primary"
      variant="contained"
      type="submit"
      form={form}
      disabled={isDisabled || !is_submit_available}
      sx={{
        padding: '6px 32px',
        position: 'relative',
        minHeight: '40px',
        width: 'fit-content',
        ...(withMargin && {
          marginTop: '24px',
        }),
      }}
      {...props}
    >
      {is_submit_pending && (
        <Box
          sx={{
            display: 'flex',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <CircularProgress size={24} />
        </Box>
      )}
      <Typography sx={{ opacity: is_submit_pending ? 0 : 1, lineHeight: 1 }}>{children}</Typography>
    </Button>
  );
};
