import { useSelector } from 'react-redux';
import { AppState } from 'types';

export enum ThemeMode {
  Dark = 'dark',
  Light = 'light',
}

export const useThemeMode = () => {
  const mode = useSelector((state: AppState) => state.theme.mode);
  const isDarkMode = mode === ThemeMode.Dark;
  const isLightMode = mode === ThemeMode.Light;

  return { isDarkMode, isLightMode, mode };
};
