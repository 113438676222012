const paths = {
  home: '/',
  mozrest: '/mozrest',
  auth: {
    login: '/login',
    forgotPassword: '/forgot-password',
  },
  calendar: {
    home: '/',
    calendar: '/calendar',
    reservationList: '/list',
    floorPlan: '/floor-plan',
    quickActions: '/quick-actions',
    statistics: '/statistics',
    search: '/search',
    reports: '/reports',
    database: '/database',
  },
  settings: {
    accountSetup: {
      restaurantInformation: '/settings/account-setup/restaurant-information',
      timeAndLanguage: '/settings/account-setup/time-and-language',
      timeManagement: '/settings/account-setup/time-management',
      ownerAccount: '/settings/account-setup/owner-account',
      users: '/settings/account-setup/users',
    },
    tableSettings: {
      tableManagement: '/settings/table-settings/table-management',
      diningAreas: '/settings/table-settings/dining-areas/:id',
      tableGroups: '/settings/table-settings/table-groups',
      bookingLength: '/settings/table-settings/booking-length',
    },
    calendarAndList: {
      settings: '/settings/calendar-and-list/settings',
    },
    onlineReservations: {
      widgetSettings: '/settings/online-reservations/widget-settings',
      widgetFields: '/settings/online-reservations/widget-fields',
      policiesAndTerms: '/settings/online-reservations/policies-and-terms',
      widgetIntegration: '/settings/online-reservations/widget-integration',
    },
    marketingTools: {
      experiences: {
        index: '/settings/marketing-tools/experiences',
        create: '/settings/marketing-tools/experiences/create',
      },
      events: {
        index: '/settings/marketing-tools/events',
        crate: '/settings/marketing-tools/events/create',
      },
      specialOffers: {
        index: '/settings/marketing-tools/special-offers',
        create: '/settings/marketing-tools/special-offers/create',
      },
      paymentsAndDeposits: {
        index: '/settings/marketing-tools/payments-and-deposits',
        create: '/settings/marketing-tools/payments-and-deposits/create',
      },
    },
    websiteBuilder: {
      restaurantProfile: '/settings/website-builder/restaurant-profile',
      gallery: '/settings/website-builder/gallery',
    },
  },
};

export default paths;
