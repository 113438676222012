import { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';

interface ISubNavLink {
  disabled?: boolean;
}

interface SubNavLinkProps {
  to: string;
  target?: string;
  children: ReactNode;
  onClick: (e: any) => void;
  disabled?: boolean;
}

const StyledNavLink = styled(NavLink)<ISubNavLink>(({ theme, disabled }) => ({
  textDecoration: 'none',
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
  padding: '8px',
  borderRadius: '5px',
  '.sub-nav-link': {
    color: theme.palette.navyBlue,
  },
  '& svg path': {
    stroke: theme.palette.navyBlue,
  },
  '&:hover': {
    '.sub-nav-link': {
      color: theme.palette.secondary.main,
    },
    '& svg path': {
      stroke: theme.palette.secondary.main,
    },
  },
  [`@media (min-width: 760px)`]: {
    '&.active': {
      backgroundColor: theme.palette.secondary.main,
      '.sub-nav-link': {
        color: theme.palette.white,
      },
      '& svg path': {
        stroke: theme.palette.white,
      },
    },
  },
  ...(disabled && {
    cursor: 'not-allowed',
    '.sub-nav-link': {
      color: theme.palette.disabled2,
    },
    '& svg path': {
      stroke: theme.palette.disabled2,
    },
    '&:hover': {
      '.sub-nav-link': {
        color: theme.palette.disabled2,
      },
      '& svg path': {
        stroke: theme.palette.disabled2,
      },
    },
  }),
}));

export const SubNavLink = ({ children, ...props }: SubNavLinkProps) => {
  return (
    <StyledNavLink style={{ textDecoration: 'none' }} {...props}>
      {children}
    </StyledNavLink>
  );
};
