export enum AvailableLanguages {
  SQ = 'sq',
  AR = 'ar',
  AZ = 'az',
  BG = 'bg',
  CA = 'ca',
  ZH_HANS = 'zh-hans',
  ZH_HANT = 'zh-hant',
  CS = 'cs',
  DA = 'da',
  NL = 'nl',
  EN = 'en',
  ET = 'et',
  FI = 'fi',
  FR = 'fr',
  DE = 'de',
  EL = 'el',
  HU = 'hu',
  IS = 'is',
  IT = 'it',
  JA = 'ja',
  LV = 'lv',
  LT = 'lt',
  NN = 'nn',
  PL = 'pl',
  PT_PT = 'pt-pt',
  RO = 'ro',
  RU = 'ru',
  SL = 'sl',
  ES = 'es',
  SV = 'sv',
  TH = 'th',
}
