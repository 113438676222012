import paths from '../../paths';
import { lazy } from 'react';

const TableManagementSettingsPage = lazy(
  () => import('app/pages/RestaurantSettings/TableManagement'),
);
const TableGroupsPage = lazy(() => import('app/pages/RestaurantSettings/TableGroups'));
const DiningAreasPage = lazy(
  () => import('app/pages/RestaurantSettings/TableManagement/_components/DiningAreas'),
);

const tableSettings = [
  {
    path: paths.settings.tableSettings.tableManagement,
    element: <TableManagementSettingsPage />,
  },
  {
    path: paths.settings.tableSettings.tableGroups,
    element: <TableGroupsPage />,
  },
  {
    path: paths.settings.tableSettings.diningAreas,
    element: <DiningAreasPage />,
  },
];

export default tableSettings;
