import paths from '../../paths';
import { lazy } from 'react';

const BasicInformationPage = lazy(() => import('app/pages/RestaurantSettings/BasicInformation'));
const TimeAndLanguagePage = lazy(() => import('app/pages/RestaurantSettings/TimeAndLanguage'));
const OwnerPage = lazy(() => import('app/pages/RestaurantSettings/Owner'));
const UserAccounts = lazy(() => import('app/pages/RestaurantSettings/UserAccounts'));
const TimeManagement = lazy(() => import('app/pages/RestaurantSettings/TimeManagement'));

const accountSetup = [
  {
    path: paths.settings.accountSetup.restaurantInformation,
    element: <BasicInformationPage />,
  },
  {
    path: paths.settings.accountSetup.timeAndLanguage,
    element: <TimeAndLanguagePage />,
  },
  {
    path: paths.settings.accountSetup.ownerAccount,
    element: <OwnerPage />,
  },
  {
    path: paths.settings.accountSetup.timeManagement,
    element: <TimeManagement />,
  },
  {
    path: paths.settings.accountSetup.users,
    element: <UserAccounts />,
  },
];

export default accountSetup;
