import { lazy } from 'react';
import paths from '../paths';

const HomePage = lazy(() => import('app/pages/App/Home'));
const CalendarPage = lazy(() => import('app/pages/App/Calendar'));
const ReservationListPage = lazy(() => import('app/pages/App/ReservationList'));
const FloorPlanPage = lazy(() => import('app/pages/App/FloorPlan'));
const QuickActionsPage = lazy(() => import('app/pages/App/QuickActions'));
const StatisticsPage = lazy(() => import('app/pages/App/StatisticsPage'));
const SearchPage = lazy(() => import('app/pages/App/SearchPage'));

const calendar = [
  {
    path: paths.calendar.home,
    element: <HomePage />,
  },
  {
    path: paths.calendar.calendar,
    element: <CalendarPage />,
  },
  {
    path: paths.calendar.reservationList,
    element: <ReservationListPage />,
  },
  {
    path: paths.calendar.floorPlan,
    element: <FloorPlanPage />,
  },
  {
    path: paths.calendar.quickActions,
    element: <QuickActionsPage />,
  },
  {
    path: paths.calendar.statistics,
    element: <StatisticsPage />,
  },
  {
    path: paths.calendar.search,
    element: <SearchPage />,
  },
];

export default calendar;
