import paths from '../paths'
import { lazy } from 'react'

const MozrestIntegrationPage = lazy(() => import('../../pages/MozrestIntegration'))

const mozrest = [
  {
    path: paths.mozrest,
    element: <MozrestIntegrationPage />,
  }
]

export default mozrest