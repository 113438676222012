import { lazy } from 'react';
import paths from 'app/router/paths';

const RestaurantProfilePage = lazy(() => import('app/pages/RestaurantSettings/RestaurantProfile'));
const Gallery = lazy(() => import('app/pages/RestaurantSettings/Gallery'));

const websiteBuilder = [
  {
    path: paths.settings.websiteBuilder.restaurantProfile,
    element: <RestaurantProfilePage />,
  },
  {
    path: paths.settings.websiteBuilder.gallery,
    element: <Gallery />,
  },
];

export default websiteBuilder;
