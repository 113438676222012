import { useMediaQuery, useTheme } from '@mui/material';
import { isMobileQuery } from 'utils/style/screenQuery';

export const useScreenSize = () => {
  const theme = useTheme();

  const isMobile = useMediaQuery(`(max-width:${isMobileQuery})`);
  const isMinSmScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const isMinXlScreen = useMediaQuery('(min-width: 1440px)');
  const isMinLgScreen = useMediaQuery('(min-width: 1200px)');
  const isMinLargerSmScreen = useMediaQuery('(min-width: 800px)');
  const isMinSmallerSmScreen = useMediaQuery('(min-width: 400px)');
  const isMaxXlScreen = useMediaQuery('(max-width: 1535px)');
  const isMax900 = useMediaQuery('(max-width: 900px)');
  const isMax760 = useMediaQuery('(max-width: 760px)');

  const isMax500 = useMediaQuery('(max-width: 500px)');
  const isBetween501And600 = useMediaQuery('(min-width: 501px) and (max-width: 600px)');
  const isMax600 = useMediaQuery('(max-width: 600px)');
  const isBetween992And1080 = useMediaQuery('(min-width: 992px) and (max-width: 1080px)');
  const isBetween1081And1170 = useMediaQuery('(min-width: 1081px) and (max-width: 1170px)');

  const isBetween901And1340 = useMediaQuery('(min-width:901px) and (max-width:1340px)');
  const isBetween561And620 = useMediaQuery('(min-width:561px) and (max-width:620px)');
  const isBetween521And560 = useMediaQuery('(min-width:521px) and (max-width:560px)');
  const isLessThan520 = useMediaQuery('(max-width:520px)');

  return {
    isMobile,
    isMinSmScreen,
    isMinLargerSmScreen,
    isMinSmallerSmScreen,
    isMinLgScreen,
    isMinXlScreen,
    isMaxXlScreen,
    isMax900,
    isMax760,
    isMax500,
    isBetween501And600,
    isMax600,
    isBetween992And1080,
    isBetween1081And1170,
    isBetween901And1340,
    isBetween561And620,
    isBetween521And560,
    isLessThan520,
  };
};
