const API_ROOT = '/api-app/v3';

const ACCOUNT_ROOT = `${API_ROOT}/account`;
const RESTAURANT_ROOT = `${API_ROOT}/restaurant`;
const CALENDAR_ROOT = `${API_ROOT}/calendar`;
const UPLOAD_ROOT = `${API_ROOT}/upload`;
const CONFIG_ROOT = `${API_ROOT}/config`;
const SETTINGS_ROOT = `${RESTAURANT_ROOT}/settings`;
const RESERVATIONS_ROOT = `${RESTAURANT_ROOT}/reservations`;
const RESERVATION_ROOT = `${RESTAURANT_ROOT}/reservation`;
const TIME_MANAGEMENT = `${RESTAURANT_ROOT}/time-management`;
const TABLE_MANAGEMENT = `${RESTAURANT_ROOT}/table-management`;

export const ENDPOINT_URLS = {
  ACCOUNT: {
    ROOT: ACCOUNT_ROOT,
    USER: {
      ROOT: `${ACCOUNT_ROOT}/user`,
      USERS: `${ACCOUNT_ROOT}/users`,
    },
    PROFILE: {
      ROOT: `${ACCOUNT_ROOT}/profile`,
    },
    WAITER: {
      // ROOT: `${ACCOUNT_ROOT}/waiter`,
    },
  },
  RESTAURANT: {
    ROOT: RESTAURANT_ROOT,
    ROOMS: `${TABLE_MANAGEMENT}/rooms`,
    TABLES: `${TABLE_MANAGEMENT}/tables`,
    SPECIAL_OFFERS: `${RESTAURANT_ROOT}/special-offers`,
    TIME_MANAGEMENT: {
      DEFAULT_BOOKING_LENGTH: `${TIME_MANAGEMENT}/default-booking-length`,
      CUSTOM_BOOKING_LENGTH: `${TIME_MANAGEMENT}/custom-booking-length`,
      CUSTOM_BOOKING_LENGTH_ORDER: `${TIME_MANAGEMENT}/custom-booking-length/order`,
    },
    TABLE_MANAGEMENT: {
      LAYOUTS: `${TABLE_MANAGEMENT}/layouts`,
      ROOMS: `${TABLE_MANAGEMENT}/rooms`,
      ROOMS_ORDER: `${TABLE_MANAGEMENT}/rooms/order`,
      TABLES: `${TABLE_MANAGEMENT}/tables`,
      GROUPS: `${TABLE_MANAGEMENT}/groups`,
    },
    CLIENT_SEARCH: `${RESTAURANT_ROOT}/search/reservation/client`,
    RESERVATIONS_SEARCH: `${RESTAURANT_ROOT}/search/reservation`,
    RESERVATION: {
      ROOT: RESERVATION_ROOT,
      COMMENT: `${RESERVATION_ROOT}/comment`,
      RESERVATIONS: {
        ROOT: RESERVATIONS_ROOT,
        DETAILS: `${RESERVATIONS_ROOT}/details`,
      },
    },
    SETTINGS: {
      WIDGET: `${SETTINGS_ROOT}/widget`,
      CALENDAR: `${SETTINGS_ROOT}/calendar`,
      TIME_AND_LANGUAGE: `${SETTINGS_ROOT}/time-language`,
      OPENING_HOURS: `${SETTINGS_ROOT}/opening-hours`,
      POLICIES_AND_TERMS: `${SETTINGS_ROOT}/policies-terms`,
      NOTIFICATION: {
        SMS: `${SETTINGS_ROOT}/notification/sms`,
        EMAIL: `${SETTINGS_ROOT}/notification/email`,
      },
    },
  },
  FILE: {
    ROOT: UPLOAD_ROOT,
    STORAGE: '/storage',
  },
  CALENDAR: {
    ROOT: `${CALENDAR_ROOT}/reservation`,
  },
  CONFIG: {
    ROOT: CONFIG_ROOT,
  },
};
