export enum DateFormatKey {
  dmy = 'HH:mm',
  dmya = 'hh:mm A',
  mdy = 'HH:mm',
  mdya = 'hh:mm A',
  ymd = 'HH:mm',
}

export enum DatesMoment {
  dmy = 'DD/MM/YYYY',
  dmya = 'DD/MM/YYYY',
  mdy = 'MM/DD/YYYY',
  mdya = 'MM/DD/YYYY',
  ymd = 'YYYY-MM-DD',
}

export enum DateFormatValues {
  YMD = 'ymd',
  DMY = 'dmy',
  MDY = 'mdy',
  DMYA = 'dmya',
  MDYA = 'mdya',
}
