import { LithuanianFlag } from 'app/components/Flags/lt';
import { EnglishFlag } from 'app/components/Flags/en';
import { AzerbaijanFlag } from 'app/components/Flags/az';
import { ChineseFlag } from 'app/components/Flags/zh';
import { DanishFlag } from 'app/components/Flags/da';
import { EthiopianFlag } from 'app/components/Flags/et';
import { GermanFlag } from 'app/components/Flags/de';
import { IcelandicFlag } from 'app/components/Flags/is';
import { LatvianFlag } from 'app/components/Flags/lv';
import { PortugueseFlag } from 'app/components/Flags/pt';
import { SlovenianFlag } from 'app/components/Flags/sl';
import { ThaiFlag } from 'app/components/Flags/th';
import { AlbanianFlag } from 'app/components/Flags/sq';
import { BulgarianFlag } from 'app/components/Flags/bg';
import { DutchFlag } from 'app/components/Flags/nl';
import { FinnishFlag } from 'app/components/Flags/fi';
import { GreekFlag } from 'app/components/Flags/el';
import { ItalianFlag } from 'app/components/Flags/it';
import { NorwegianFlag } from 'app/components/Flags/nn';
import { RomanianFlag } from 'app/components/Flags/ro';
import { SpanishFlag } from 'app/components/Flags/es';
import { ArabicFlag } from 'app/components/Flags/ar';
import { CzechFlag } from 'app/components/Flags/cs';
import { FrenchFlag } from 'app/components/Flags/fr';
import { HungarianFlag } from 'app/components/Flags/hu';
import { JapaneseFlag } from 'app/components/Flags/ja';
import { PolishFlag } from 'app/components/Flags/pl';
import { RussianFlag } from 'app/components/Flags/ru';
import { SwedishFlag } from 'app/components/Flags/sv';
import { CatalanFlag } from 'app/components/Flags/ca';
import { EstonianFlag } from 'app/components/Flags/ee';
import { CroatianFlag } from 'app/components/Flags/hr';
import { MalteseFlag } from 'app/components/Flags/mt';

export const flags = {
  lt: <LithuanianFlag />,
  en: <EnglishFlag />,
  gb: <EnglishFlag />,
  az: <AzerbaijanFlag />,
  'zh-hans': <ChineseFlag />,
  'zh-hant': <ChineseFlag />,
  da: <DanishFlag />,
  et: <EthiopianFlag />,
  de: <GermanFlag />,
  is: <IcelandicFlag />,
  lv: <LatvianFlag />,
  'pt-pt': <PortugueseFlag />,
  sl: <SlovenianFlag />,
  th: <ThaiFlag />,
  sq: <AlbanianFlag />,
  bg: <BulgarianFlag />,
  nl: <DutchFlag />,
  fi: <FinnishFlag />,
  el: <GreekFlag />,
  it: <ItalianFlag />,
  nn: <NorwegianFlag />,
  ro: <RomanianFlag />,
  es: <SpanishFlag />,
  ar: <ArabicFlag />,
  cs: <CzechFlag />,
  fr: <FrenchFlag />,
  hu: <HungarianFlag />,
  ja: <JapaneseFlag />,
  pl: <PolishFlag />,
  ru: <RussianFlag />,
  sv: <SwedishFlag />,
  ca: <CatalanFlag />,
  ee: <EstonianFlag />,
  hr: <CroatianFlag />,
  mt: <MalteseFlag />,
};
