import {
  Origin,
  PromotionType,
  Statuses,
  ReservationDietaryRequirementsTags,
  ReservationClientTags,
} from 'types/reservation';
import { IReservation } from 'types/app/reservations';

export interface IReservationBadgeConditions {
  areTagsExist: boolean;
  guestCondition: boolean;
  hasPayments: boolean;
  isBigSpender: boolean;
  isPromotionType: boolean;
  isVip: boolean;
  isWalkInStatus: boolean;
  originCondition: boolean;
  paymentCondition: boolean;
  reservationOrigin: Origin;
  showDepositIcon: boolean;
  showDiscountIcon: boolean;
  showEventIcon: boolean;
  showInitialTimeBadge: boolean;
  showPaidIcon: boolean;
  showSavedCardIcon: boolean;
  specialOfferCondition: boolean;
  showTime: boolean;
  areGuestTagsExist: boolean;
  showCommentCondition: boolean;
  isDietaryRequirementExist: boolean;
  isBlacklisted: boolean;
  isLocked: boolean;
  isImportantMessage: boolean;
}

export const useReservationBadgeConditions = (config?: {
  big_spender_tag?: boolean;
  original_time?: boolean;
  statusVisibilityOverrides?: any;
}) => {
  const { big_spender_tag, original_time, statusVisibilityOverrides } = config || {};

  const defaultVisibility = {
    showNoOfGuests: true,
    showOrigin: true,
    showComment: true,
    showVip: true,
    showBigSpender: true,
    showTime: true,
  };

  const statusVisibility = (status: Statuses) => {
    return (statusVisibilityOverrides && statusVisibilityOverrides[status]) || defaultVisibility;
  };

  return (reservation: IReservation): IReservationBadgeConditions => {
    const reservationStatus = reservation?.status;
    const { showNoOfGuests, showOrigin, showComment, showTime, showVip, showBigSpender } =
      statusVisibility(reservationStatus);

    // const hasPriceAmount = reservation?.special_offer?.price?.amount;
    // const hasPayments = !!reservation?.payments?.length;
    // const showPaidIcon = promotionType && hasPriceAmount;
    //TODO other conditions

    const showCommentCondition =
      showComment && reservation?.comments && reservation?.comments.length > 0;

    const promotionType = reservation?.special_offer?.type;

    const isWalkInStatus = reservationStatus === Statuses.WalkIn;

    const reservationOrigin = reservation.origin;

    const tags = reservation?.tags;

    const guestTags = reservation?.client?.tags;

    const filteredGuestTags = guestTags?.filter(
      (tag) =>
        tag !== ReservationClientTags.Vip &&
        tag !== ReservationClientTags.BlackListed &&
        tag !== ReservationClientTags.BigSpender,
    );

    const areTagsExist = !!tags?.length;

    const areGuestTagsExist = !!filteredGuestTags?.length;

    const hasPriceAmount = false;

    const hasPayments = false;

    const showSpecialOfferIcon = promotionType === PromotionType.Product;

    const showEventIcon = PromotionType.Event === promotionType;

    const showSavedCardIcon = PromotionType.LateCancellation === promotionType;

    const showDepositIcon = PromotionType.Deposit === promotionType;

    const showPaidIcon = false;
    //TODO && special_offer.discount
    const showDiscountIcon = PromotionType.Discount === promotionType;

    const specialOfferCondition = showSpecialOfferIcon && !hasPriceAmount;

    const originCondition = showOrigin && !isWalkInStatus && !!reservation.origin;

    const paymentCondition = showPaidIcon || (hasPayments && !showPaidIcon);

    const guestCondition = showNoOfGuests && !!reservation.guests;

    const isVip = showVip && guestTags?.includes(ReservationClientTags.Vip);

    const isBigSpender =
      showBigSpender && guestTags?.includes(ReservationClientTags.BigSpender) && big_spender_tag;

    const isBlacklisted = guestTags?.includes(ReservationClientTags.BlackListed) || false;

    const isLocked = reservation?.lock_table || false;

    const showInitialTimeBadge = !!original_time;

    const reservationTags = reservation?.tags;

    const isDietaryRequirementExist =
      reservationTags?.some((tag) =>
        Object.values(ReservationDietaryRequirementsTags as any).includes(tag),
      ) || false;

    const isImportantMessage = !!reservation?.important_message;

    return {
      isWalkInStatus,
      isVip,
      isBigSpender,
      isPromotionType: !!promotionType,
      reservationOrigin,
      originCondition,
      areTagsExist,
      specialOfferCondition,
      showEventIcon,
      showSavedCardIcon,
      showDepositIcon,
      showDiscountIcon,
      paymentCondition,
      guestCondition,
      showPaidIcon,
      hasPayments,
      showInitialTimeBadge,
      showTime,
      areGuestTagsExist,
      showCommentCondition,
      isDietaryRequirementExist,
      isBlacklisted,
      isLocked,
      isImportantMessage,
    };
  };
};
