import { createContext, Dispatch, SetStateAction, Suspense, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { SettingsSubNavigation } from '../SettingsSubNavigation';
import { useScreenSize } from 'hooks/useScreenSize';
import { CustomBackdrop } from 'app/components/Overlays/CustomBackdrop';
import { Box } from '@mui/material';

export interface INavigationValues {
  visible: boolean;
}

interface NavigationContextProps {
  visible: boolean;
  setNavigationValues: Dispatch<SetStateAction<INavigationValues>>;
}

export const NavigationContext = createContext<NavigationContextProps>({
  visible: true,
  setNavigationValues: () => {},
});

const SettingsWrapper = () => {
  const [navigationValues, setNavigationValues] = useState({ visible: false });
  const { isMobile, isMax760 } = useScreenSize();

  const showMobileSubNavigation = isMobile ? !navigationValues.visible : true;
  const showOutlet = isMax760 ? navigationValues.visible : true;

  return (
    <NavigationContext.Provider
      value={{
        ...navigationValues,
        setNavigationValues,
      }}
    >
      {showMobileSubNavigation && (
        <SettingsSubNavigation
          setNavigationValues={setNavigationValues}
          visible={navigationValues.visible}
        />
      )}

      <Suspense fallback={<CustomBackdrop />}>
        {showOutlet && (
          <Box
            sx={{
              position: 'relative',
              flex: 1,
              width: '100%',
              height: '100%',
            }}
          >
            <Outlet />
          </Box>
        )}
      </Suspense>
    </NavigationContext.Provider>
  );
};

export default SettingsWrapper;
