import { ReactComponent as HomeIcon } from 'images/icons/ic-home.svg';
import { ReactComponent as TabletIcon } from 'images/icons/ic-tablet.svg';
import { ReactComponent as HourglassIcon } from 'images/icons/ic-hourglass.svg';
import { ReactComponent as UserIcon } from 'images/icons/ic-user.svg';
import { ReactComponent as SettingsIcon } from 'images/icons/ic-settings-icon.svg';
import { ReactComponent as LayoutGridIcon } from 'images/icons/ic-layout-grid.svg';
import { ReactComponent as CodeIcon } from 'images/icons/ic-code-snippet.svg';
import { ReactComponent as AnnouncementIcon } from 'images/icons/ic-announcement.svg';
import { ReactComponent as LayoutLeftIcon } from 'images/icons/ic-layout-left.svg';
import { ReactComponent as MailIcon } from 'images/icons/ic-mail.svg';
import { ReactComponent as EditIcon } from 'images/icons/ic-edit.svg';
import { ReactComponent as AlertIcon } from 'images/icons/ic-alert-circle.svg';
import { ReactComponent as MessageIcon } from 'images/icons/ic-message-smile-circle.svg';
import { ReactComponent as CoinIcon } from 'images/icons/ic-coins-stacked.svg';
import { ReactComponent as LifeBouyIcon } from 'images/icons/ic-life-buoy.svg';
import { ReactComponent as GalleryIcon } from 'images/icons/ic-image-plus.svg';
import { ReactComponent as MenuIcon } from 'images/icons/ic-menu-editor.svg';
import { ReactComponent as UsersSecondIcon } from 'images/icons/ic-users-icon.svg';
import { ReactComponent as UsersPlusIcon } from 'images/icons/ic-users-plus.svg';
import { ReactComponent as InboxIcon } from 'images/icons/ic-inbox.svg';
import { ReactComponent as SpecialStarIcon } from 'images/icons/ic-special-star.svg';
import { ReactComponent as BookmarkIcon } from 'images/icons/ic-bookmark-add.svg';
import { ReactComponent as HelpSquareIcon } from 'images/icons/ic-help-square.svg';
import { ReactComponent as PhoneIcon } from 'images/icons/ic-phone-call.svg';
import { oldAppUrlGenerate } from 'utils/data-processors/url';
import { TFunction } from 'i18next';
import paths from 'app/router/paths';

export const settingsSubNavigationLinks = (t: TFunction) => [
  {
    title: t('settingsAccountSetup'),
    links: [
      {
        icon: <HomeIcon />,
        to: paths.settings.accountSetup.restaurantInformation,
        name: t('settingsBasicInformation'),
      },
      {
        icon: <HourglassIcon />,
        to: paths.settings.accountSetup.timeAndLanguage,
        name: t('settingsTimeAndLanguage'),
      },
      {
        icon: <HourglassIcon />,
        to: paths.settings.accountSetup.timeManagement,
        name: t('settingsTimeManagement'),
      },
      {
        icon: <UserIcon />,
        // to: PATH.OWNER,
        to: oldAppUrlGenerate('settings/account-settings', false),
        target: '_blank',
        name: t('settingsManagerAccount'),
      },
      {
        icon: <UsersSecondIcon />,
        // to: PATH.USER_ACCOUNTS,
        to: oldAppUrlGenerate('settings/accounts', false),
        target: '_blank',
        name: t('settingsUsers'),
      },
    ],
  },
  {
    title: t('settingsTableSettings'),
    links: [
      {
        icon: <SettingsIcon />,
        to: paths.settings.tableSettings.tableManagement,
        name: t('settingsDiningRoomsAndTables'),
      },
      {
        icon: <LayoutGridIcon />,
        to: paths.settings.tableSettings.tableGroups,
        name: t('settingsTableGroups'),
      },
      {
        icon: <TabletIcon />,
        to: oldAppUrlGenerate('settings/floor-plan', false),
        target: '_blank',
        name: t('settingsFloorPlanSetup'),
      },
    ],
  },
  {
    title: t('settingsTimeManagement'),
    links: [
      {
        icon: <HourglassIcon />,
        to: oldAppUrlGenerate('settings/opening-hours', false),
        target: '_blank',
        name: t('settingsOpeningHours'),
      },
      {
        icon: <CodeIcon />,
        to: '/',
        name: t('settingsCustomPeriods'),
        disabled: true,
      },
    ],
  },
  {
    title: t('settingsCalendarAndListSettings'),
    links: [
      {
        icon: <SettingsIcon />,
        to: paths.settings.calendarAndList.settings,
        // to: oldAppUrlGenerate('settings/reservations-settings', false),
        // target: '_blank',
        name: t('settingsWebsiteSettings'),
      },
    ],
  },
  {
    title: t('settingsNotifications'),
    links: [
      {
        icon: <MailIcon />,
        to: oldAppUrlGenerate('settings/email-notifications', false),
        target: '_blank',
        name: t('settingsEmailSettings'),
      },
      {
        icon: <EditIcon />,
        to: oldAppUrlGenerate('settings/sms-notifications', false),
        target: '_blank',
        name: t('settingsSMSSettings'),
      },
    ],
  },
  {
    title: t('settingsOnlineReservations'),
    links: [
      {
        icon: <SettingsIcon />,
        to: paths.settings.onlineReservations.widgetSettings,
        name: t('settingsWidgetSettings'),
      },
      {
        icon: <SettingsIcon />,
        // to: PATH.WIDGET_FIELDS,
        to: oldAppUrlGenerate('settings/widget-settings', false),
        target: '_blank',
        name: t('settingsWidgetFields'),
      },
      {
        icon: <LayoutLeftIcon />,
        to: paths.settings.onlineReservations.policiesAndTerms,
        name: t('settingsPoliciesAndTerms'),
      },
      {
        icon: <CodeIcon />,
        // to: PATH.WIDGET_INTEGRATION,
        to: oldAppUrlGenerate('settings/widget-integration', false),
        target: '_blank',
        name: t('settingsWidgetIntegration'),
      },
      {
        icon: <AnnouncementIcon />,
        to: '/',
        name: t('settingsMarketingChannels'),
        disabled: true,
      },
    ],
  },

  {
    title: t('settingsTools'),
    links: [
      {
        icon: <AlertIcon />,
        to: paths.settings.marketingTools.experiences.index,
        name: t('settingsExperiences'),
        disabled: true,
      },
      {
        icon: <MessageIcon />,
        to: paths.settings.marketingTools.events.index,
        name: t('settingsEvents'),
        disabled: true,
      },
      {
        icon: <SpecialStarIcon />,
        to: paths.settings.marketingTools.specialOffers.index,
        name: t('settingsSpecialOffers'),
        disabled: true,
      },
      {
        icon: <CoinIcon />,
        // to: PATH.DEPOSITS,
        to: oldAppUrlGenerate('settings/prepayments-and-promotions', false),
        target: '_blank',
        name: t('settingsPrepaymentsAndDeposits'),
      },
      {
        icon: <LifeBouyIcon />,
        to: oldAppUrlGenerate('settings/integrations', false),
        target: '_blank',
        name: t('settings3rdPartyIntegrations'),
      },
      {
        icon: <BookmarkIcon />,
        to: '/',
        name: t('settingsBanners'),
        disabled: true,
      },
    ],
  },
  {
    title: t('settingsWebsiteBuilder'),
    links: [
      {
        icon: <SettingsIcon />,
        to: paths.settings.websiteBuilder.restaurantProfile,
        name: t('settingsRestaurantProfile'),
        disabled: true,
      },
      {
        icon: <GalleryIcon />,
        to: paths.settings.websiteBuilder.gallery,
        name: t('settingsWebsiteGallery'),
        disabled: true,
      },
      {
        icon: <CodeIcon />,
        to: '/',
        name: t('settingsWebsiteMenuSettings'),
        disabled: true,
      },
      {
        icon: <MenuIcon />,
        to: '/',
        name: t('settingsWebsiteMenuEditor'),
        disabled: true,
      },
    ],
  },
  {
    title: t('settingsSubscription'),
    links: [
      {
        icon: <UsersPlusIcon />,
        to: oldAppUrlGenerate('settings/subscription', false),
        target: '_blank',
        name: t('settingsSubscriptionAndBillingData'),
      },
      {
        icon: <InboxIcon />,
        to: oldAppUrlGenerate('settings/invoices', false),
        target: '_blank',
        name: t('settingsInvoices'),
      },
    ],
  },
  {
    title: t('settingsSupport'),
    links: [
      {
        icon: <HelpSquareIcon />,
        to: '/',
        name: t('settingsChat'),
        disabled: true,
      },
      {
        icon: <HelpSquareIcon />,
        to: 'https://help.tablein.com/',
        target: '_blank',
        name: t('settingsHelpPages'),
      },
      {
        icon: <PhoneIcon />,
        to: 'https://help.tablein.com/contact-tablein',
        target: '_blank',
        name: t('settingsContactTablein'),
      },
    ],
  },
];
