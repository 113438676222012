import { AppState } from 'types';
import { useSelector } from 'react-redux';
import { useAppSelector } from 'redux/hooks';

export const useStatus = () => {
  const isReadOnlyUser = useSelector((state: AppState) => state.auth.userSettings.readOnly);
  const status = useSelector((state: AppState) => state.system.status);
  const { isOffline, isServerAvailable } = useSelector(
    (state: AppState) => state.application.connectionStatus,
  );

  const {
    drupal_permissions: { permission_to_delete, read_only },
  } = useAppSelector((state) => state.app.profile);

  const isReadOnlySystemStatus = status === 'read_only';
  const isReadOnly =
    read_only || isReadOnlyUser || isReadOnlySystemStatus || isOffline || !isServerAvailable;

  return {
    isServerAvailable,
    isOffline,
    status,
    isReadOnlyUser,
    isReadOnly,
    isReadOnlySystemStatus,
    permission_to_delete,
  };
};
